import React, { useCallback, useState, useEffect, useRef } from 'react';
import classNames from "classnames";
import { useAuth } from '../../../hooks/auth';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import InputMask from "react-input-mask";
import * as Yup from 'yup';
import { ButtonsList, GridFooterCard, ContentModal, Content } from './styles';
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import {
  AppBar, Toolbar, IconButton, Typography, Button, Grid, Divider,
  DialogTitle as MuiDialogTitle, DialogContent as MuiDialogContent, DialogActions as MuiDialogActions, Dialog, InputAdornment, CircularProgress, Input, FormControl, InputLabel, FormHelperText, Grow, Paper, Breadcrumbs, Link, useScrollTrigger, Zoom, Fab
} from "@material-ui/core";
import { Skeleton } from '@material-ui/lab';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { Assignment, PersonPinCircle, MonetizationOn, AccountBox, PowerSettingsNew, Event, Close as CloseIcon, Lock, AccountCircle, HelpOutline, ArrowForwardIos, PersonAdd, ArrowLeft, KeyboardArrowUp } from '@material-ui/icons';
import MenuIcon from "@material-ui/icons/Menu";

import profile from "../../assets/img/christian.jpg";
import api from '../../../services/api';
import CardHeader from '../../../components/Card/CardHeader';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import NumberFormat from 'react-number-format';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import SignInModal from '../../Login/SignInModal';
import { CardTextHeader } from '../../Login/SignIn/styles';
import { green, blue, red } from '@material-ui/core/colors';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../hooks/toast';
import Header from '../../../components/Header';
import { IPlanItem } from '../../../models/Plans/IPlanDTO';
import DialogUnidade from '../../../components/DialogUnidade/Index';
import DialogPlanDetails from '../../../components/DialogPlanDetails/Index';
import { SegundoPasso } from '../../Login/SignIn';
import { Helmet } from 'react-helmet';
import { IUserLogin } from '../../../models/Perfil';


const useStyles = makeStyles((theme: Theme) =>



  createStyles({
    root: {
      flexGrow: 1,

    },
    valuePlan: {
      marginTop: '2px !important',
    },
    carrouselLogin: {
      display: 'none',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: '60px 0 0',
      width: '100%',
      position: 'relative',
    },

    SpanCard: {
      color: 'rgba(0,0,0,0.5)',
      display: 'flex',
      alignItems: 'center',

    },
    IconInfo: {
      color: blue[500],
    },
    RingOff: {
      'outline-style': 'inherit',
    },
    carrouselInvisible: {
      display: 'none',
    },
    noPlans: {
      padding: '60px',
      opacity: '0.7',
      '& h5': {
        color: theme.palette.text.secondary,
      }
    },
    btnItemLogin: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',

    },
    btnItemLoginComponent: {
      marginBottom: '50px',
    },
    textItemLogin: {
      margin: '26px 0',
    },
    textCenter: {
      textAlign: 'center',
    },
    noClasses: {
      textAlign: 'center',
      padding: '60px',
      opacity: '0.7',
      '& h5': {
        color: theme.palette.text.secondary,
      }
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",

    },
    backToTopAnchor: {
      height: '0px',
      minHeight: '0px',
    },
    marginPanel: {
      marginTop: '15px',
    },
    textObs: {
      color: theme.palette.text.secondary,
      fontSize: '13px',
    },
    ListValues: {
      padding: '0.2375rem 1.075rem',
      flex: '1 1 auto',
    },
    overflowCard: {
      overflow: 'inherit !important',
    },
    btnEsgotado: {
      backgroundColor: red[500],
      borderColor: red[500],
      opacity: '0.8',
      color: '#fff',
      '&:hover': {
        backgroundColor: red[700],
        borderColor: red[700],

      },
      '&:active': {

        backgroundColor: red[700],
        borderColor: red[700],
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem red[700]',
      },
    }
  },


  ),
);


interface FindCPFFormData {
  cpf: string;
}

interface SignInFormData {
  password: string;
}


interface PropsZoom {
  window?: () => Window;
  children: React.ReactElement;
}

const useStylesZoom = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

function ScrollTop(props: PropsZoom) {
  const { children, window } = props;
  const classes = useStylesZoom();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });


const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
var dataFormsCpf: FindCPFFormData;


type RouteParams = {
  id: string
}

type CompraOnlineProps = RouteComponentProps<RouteParams>;

const List: React.FC<CompraOnlineProps> = ({ match }) => {
  const classes = useStyles();
  const { user, tenant, signIn, updateTenant } = useAuth();

  const [listPlans, setListPlans] = useState<IPlanItem[]>([]);
  const [planSelected, setPlanSelected] = useState<IPlanItem>();
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalLogin, setOpenModalLogin] = React.useState(false);
  const [openModalSignUp, setOpenModalSignUp] = React.useState(false);
  const [startEffect, setStartEffect] = React.useState(false);
  const [openUnidade, setOpenUnidade] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [openDetails, setOpenDetails] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingPlans, setLoadingPlans] = React.useState(true);
  const [maxWidthDialog, setMaxWidthDialog] = React.useState<"sm" | "xs">("sm");
  const [userTemp, setUserTemp] = React.useState<IUserLogin>();
  const [segundoPasso, setSegundoPasso] = React.useState<SegundoPasso>(SegundoPasso.loginSenha);
  const { addToast } = useToast();
  const history = useHistory();

  const cpfRef = useRef<HTMLInputElement | null>(null);
  const cpfSignupRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (tenant?.permissions.newSales == false && tenant?.permissions.renewSales == false) {
      history.replace("/");
    }
  }, [])

  useEffect(() => {
    const nomeUnidade = match.params.id;

    const unidades = tenant.branchs.map(f => ({ nome: f.name.toLowerCase().replace(" ", "-"), id: f.id }));

    if (nomeUnidade) {
      const unidadeLocalizada = unidades.find(f => f.nome == nomeUnidade.toLocaleLowerCase());
      if (unidadeLocalizada) {
        console.log(unidadeLocalizada)
        if (tenant.idBranch != unidadeLocalizada.id) {
          tenant.idBranch = unidadeLocalizada.id;
          tenant.name = unidadeLocalizada.nome;
          updateTenant(tenant);
        }
      }
    }
    // else{
    //   tenant.idBranch = tenant.branchs[0].id;
    //   tenant.name = tenant.branchs[0].name;
    //   updateTenant(tenant);
    // }

    if (tenant.idBranch) {
      setLoadingPlans(true);
      sessionStorage.removeItem('@HubfitPortal:plan');
      sessionStorage.removeItem('@HubfitPortal:action');
      api.get('/plans/portal', {
        params: {
          idTenant: tenant.idTenant,
          idBranch: tenant.idBranch,
        }
      }).then(response => {
        if (tenant.permissions.renewSales == false) {
          const list = response.data as IPlanItem[];
          const newList = list.filter(item => {
            return item.isRenovacao == false;
          });
          setListPlans(newList);
        } else {
          setListPlans(response.data);
        }
        console.log(response.data)
        setLoadingPlans(false);
        setStartEffect(true);
      })
    }
  }, [tenant, updateTenant]);

  const handleClickReturn = useCallback(() => {
    history.push("/");
  }, [history]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setTimeout(() => {
      setOpenModalSignUp(false);
      setOpenModalLogin(false);
      setMaxWidthDialog("sm")
      setTab(0);
    }, 800)
    sessionStorage.removeItem('@HubfitPortal:plan');
    sessionStorage.removeItem('@HubfitPortal:action');
  }, []);

  const handleClickBuy = useCallback((id: string) => {
    const planSelected = listPlans?.find((e) => e.id == id);
    console.log(planSelected)
    sessionStorage.setItem('@HubfitPortal:plan', JSON.stringify(planSelected));
    sessionStorage.setItem('@HubfitPortal:action', "plan_payment");
    if (user) {
      history.push('/payments/checkout')
    } else {
      setOpenModal(true);
    }
  }, [user, listPlans]);

  const handleClickSignIn = useCallback(() => {
    setMaxWidthDialog("xs");
    setOpenModalSignUp(false);
    setOpenModalLogin(true);

    setTimeout(() => {
      cpfRef.current?.focus();
    }, 800)

  }, [history]);

  const handleClickSignUp = useCallback(() => {
    //history.push('/signup');
    setMaxWidthDialog("xs")
    setOpenModalSignUp(true);
    setTimeout(() => {
      cpfSignupRef.current?.focus();
    }, 400)
  }, []);

  function validadeCPF<bollean>(cpf: string | null | undefined) {
    console.log(cpf)
    if (cpf) {
      cpf = cpf.replace('.', '',).replace('.', '',).replace('-', '');
      console.log(cpf)
      if (cpf == null) {
        return false;
      }
      if (cpf.length != 11) {
        return false;
      }
      if ((cpf == '00000000000') || (cpf == '11111111111') || (cpf == '22222222222') || (cpf == '33333333333') || (cpf == '44444444444') || (cpf == '55555555555') || (cpf == '66666666666') || (cpf == '77777777777') || (cpf == '88888888888') || (cpf == '99999999999')) {
        return false;
      }
      let numero: number = 0;
      let caracter: string = '';
      let numeros: string = '0123456789';
      let j: number = 10;
      let somatorio: number = 0;
      let resto: number = 0;
      let digito1: number = 0;
      let digito2: number = 0;
      let cpfAux: string = '';
      cpfAux = cpf.substring(0, 9);
      for (let i: number = 0; i < 9; i++) {
        caracter = cpfAux.charAt(i);
        if (numeros.search(caracter) == -1) {
          return false;
        }
        numero = Number(caracter);
        somatorio = somatorio + (numero * j);
        j--;
      }
      resto = somatorio % 11;
      digito1 = 11 - resto;
      if (digito1 > 9) {
        digito1 = 0;
      }
      j = 11;
      somatorio = 0;
      cpfAux = cpfAux + digito1;
      for (let i: number = 0; i < 10; i++) {
        caracter = cpfAux.charAt(i);
        numero = Number(caracter);
        somatorio = somatorio + (numero * j);
        j--;
      }
      resto = somatorio % 11;
      digito2 = 11 - resto;
      if (digito2 > 9) {
        digito2 = 0;
      }
      cpfAux = cpfAux + digito2;
      if (cpf != cpfAux) {
        return false;
      }
      else {
        return true;
      }
    } else { return false }

  }
  let RegisterSignInPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Digite a senha'),
  })
  let RegisterSignInCPFSchema = Yup.object().shape({
    cpf: Yup.string()
      .test("cpf", "CPF incorreto", (value) => validadeCPF(value))
      .required("CPF obrigatório"),
  });
  const formCpf = useForm<FindCPFFormData>({
    resolver: yupResolver(RegisterSignInCPFSchema)
  });
  const formPassword = useForm<SignInFormData>({
    resolver: yupResolver(RegisterSignInPasswordSchema)
  });
  const formCpfSignUp = useForm<FindCPFFormData>({
    resolver: yupResolver(RegisterSignInCPFSchema)
  });
  // const handleSubmitFindCPFSignUp = useCallback(async (dataForm: FindCPFFormData) => {
  //   try {

  //     setLoading(true);
  //     //Buscar por CPF na API
  //     const { data } = await api.get("/users/cpf", {
  //       params: {
  //         cpf: dataForm.cpf,
  //         idTenant: tenant.idTenant,
  //       }
  //     });
  //     switch (data.cadastro) {
  //       case 0:
  //         //Sem cadastro, direcionar para tela de cadastro.
  //         history.push({
  //           pathname: '/signup',
  //           state: {
  //             cpf: dataForm.cpf,
  //           }
  //         })
  //         break;

  //     }
  //     console.log(data);

  //   } catch (err) {
  //     console.log(err)
  //     return;
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [])
  // const handleSubmitFindCPF = useCallback(async (dataForm: FindCPFFormData) => {
  //   try {
  //     setLoading(true);
  //     //Buscar por CPF na API
  //     const { data } = await api.get("/users/cpf", {
  //       params: {
  //         cpf: dataForm.cpf,
  //         idTenant: tenant.idTenant,
  //       }
  //     });
  //     console.log(data)
  //     switch (data.cadastro) {
  //       case 0:
  //         //Criar cadastro
  //         addToast({
  //           type: "info",
  //           title: 'Novo cadastro',
  //           description: 'Não encontramos nenhum cadastro com seu CPF. Insira seus dados para criar um novo cadastro ou fale com a recepção.',
  //           time: 10000
  //         });
  //         history.push({
  //           pathname: '/signup',
  //           state: {
  //             cpf: dataForm.cpf,
  //           }
  //         })
  //         break;
  //       case 1:
  //         //Existe cadastro mas não tem login
  //         setUserTemp(data);
  //         setSegundoPasso(SegundoPasso.cadastroSenha);
  //         // if (emailSignupRef.current) {
  //         //   emailSignupRef.current.value = data.email;
  //         // }
  //         setTab(1);
  //         setTimeout(() => {
  //           passwordRef.current?.focus();
  //         }, 800);
  //         break;
  //       case 2:
  //         //Existe cadastro, fazer login
  //         setUserTemp(data);
  //         setSegundoPasso(SegundoPasso.loginSenha);
  //         setTab(1);
  //         setTimeout(() => {
  //           passwordRef.current?.focus();
  //         }, 800)
  //         break;
  //     }

  //   } catch (err) {
  //     console.log(err)

  //     return;


  //   } finally {
  //     setLoading(false);
  //   }
  // }, [])
  // const handleSubmitSigIn = useCallback(async (data: SignInFormData) => {
  //   try {
  //     console.log(userTemp)
  //     setLoading(true);

  //     if (!userTemp) {
  //       throw new Error("Cadastro não encontrado")
  //     }
  //     await signIn({
  //       email: userTemp.email,
  //       password: data.password,
  //     });

  //     history.push('/payments/checkout')
  //   } catch (err) {
  //     console.log(err)
  //     setLoading(false);

  //     addToast({
  //       type: "error",
  //       title: 'Erro na autenticação',
  //       description: 'Erro no login, verifique suas credenciais.',
  //     });

  //   } finally {
  //     setLoading(false);
  //   }
  // }, [history, signIn, addToast, userTemp])


  const handleClickDetails = useCallback((plano) => {
    setPlanSelected(plano);
    setOpenDetails(true);
  }, [])
  const handleCloseModalDetails = useCallback(() => {
    setOpenDetails(false);
  }, []);

  return (
    <>
      <Helmet>
        <title>Planos - {tenant?.name}</title>
      </Helmet>
      <Header removeBack avatar={true} tabIndex={2} />
      <Toolbar id="back-to-top-anchor" className={classes.backToTopAnchor} />
      <div className={classNames(classes.mainRaised)}>
        <div>
          <Content>
            <Grid container
              direction="row"
              justify="center"
              alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <Breadcrumbs id="titleBreadcrums" aria-label="breadcrumb">
                  <Link color="inherit" href={`/${tenant.domain}`}>
                    Portal
                  </Link>

                  <Typography variant="h6" color="primary"  >Comprar</Typography>
                  <DialogUnidade />
                </Breadcrumbs>
                <Grid className={classes.marginPanel} container spacing={3}>
                  {loadingPlans &&
                    <>
                      <Grid item xs={12} sm={6} md={4} lg={3}  >
                        <Card>
                          <CardBody>
                            <h2>
                              <Skeleton animation="wave" variant="rect" />
                            </h2>
                            <Skeleton animation="wave" variant="rect" />
                            <Skeleton animation="wave" variant="rect" />
                          </CardBody>
                          <Divider variant="fullWidth" />
                          <CardBody>
                            <h4>
                              <Skeleton animation="wave" variant="rect" />
                              <Skeleton animation="wave" variant="rect" />
                            </h4>
                          </CardBody>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}  >
                        <Card>
                          <CardBody>
                            <h2>
                              <Skeleton animation="wave" variant="rect" />
                            </h2>
                            <Skeleton animation="wave" variant="rect" />
                            <Skeleton animation="wave" variant="rect" />
                          </CardBody>
                          <Divider variant="fullWidth" />
                          <CardBody>
                            <h4>
                              <Skeleton animation="wave" variant="rect" />
                              <Skeleton animation="wave" variant="rect" />
                            </h4>
                          </CardBody>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}  >
                        <Card>
                          <CardBody>
                            <h2>
                              <Skeleton animation="wave" variant="rect" />
                            </h2>
                            <Skeleton animation="wave" variant="rect" />
                            <Skeleton animation="wave" variant="rect" />
                          </CardBody>
                          <Divider variant="fullWidth" />
                          <CardBody>
                            <h4>
                              <Skeleton animation="wave" variant="rect" />
                              <Skeleton animation="wave" variant="rect" />
                            </h4>
                          </CardBody>
                        </Card>
                      </Grid>
                    </>
                  }

                  {(!listPlans || listPlans.length == 0) && !loadingPlans &&
                    <Grid item xs={12}  >
                      <Paper elevation={3} className={classes.noClasses} >
                        <Typography align="center" variant="h5" component="h5" >Nenhum plano disponível</Typography>

                      </Paper>
                    </Grid>
                  }
                  {!loadingPlans && listPlans?.map(plan => (

                    ((plan.isRenovacao == true && tenant.permissions.renewSales == true) || (plan.isRenovacao == false && tenant.permissions.newSales == true)) &&
                    <Grow
                      key={plan.id}
                      in={startEffect}
                      style={{ transformOrigin: '0 0 0' }}
                      {...(startEffect ? { timeout: 500 * listPlans.indexOf(plan) } : {})}
                    >

                      <Grid item xs={12} sm={6} md={4} lg={3}  >
                        <Card>

                          <CardHeader >
                            <Typography variant="h4">{plan.nomePlano}</Typography>
                          </CardHeader>

                          <CardBody>
                            {plan.parcelas > 1 && !plan.dcc && (
                              <>
                                <Typography align="center">Em até {plan.parcelas}x de</Typography>
                                <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={(plan.valorTotal - plan.adesaoValor) / plan.parcelas} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                                <Typography variant="body2" className={classes.textObs} align="center">ou <NumberFormat value={(plan.valorTotal - plan.adesaoValor)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /> à vista</Typography>
                              </>
                            )}
                            {plan.parcelas == 1 && !plan.dcc && (
                              <>
                                <Typography align="center">à vista</Typography>
                                <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={plan.valorTotal - plan.adesaoValor / plan.parcelas} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                                <br />
                              </>
                            )}
                            {plan.dcc && (
                              <>
                                <Typography align="center">Mensalidade</Typography>
                                <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={plan.valorDcc} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                                <Typography variant="body2" className={classes.textObs} align="center">Cobrado mensalmente no cartão de crédito</Typography>
                              </>
                            )}


                          </CardBody>
                          <Divider variant="fullWidth" />
                          <div className={classes.ListValues}>
                            <GridFooterCard >
                              <Grid container
                                direction="row"
                                justify="space-between"
                                alignItems="center">
                                {plan.creditoValorIndividual > 0 &&
                                  <>
                                    <Grid><p>Valor por aula: </p></Grid>
                                    <Grid><NumberFormat value={plan.creditoValorIndividual} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>
                                  </>
                                }

                                {plan.adesaoValor > 0 &&
                                  <>
                                    <Grid><p>Adesão:</p></Grid>
                                    <Grid><NumberFormat value={plan.adesaoValor} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>
                                  </>

                                }
                              </Grid>
                            </GridFooterCard>
                          </div>
                          <CardFooter>
                            <Grid container
                              spacing={1}
                              direction="row"
                              justify="center"
                              alignItems="center">
                              <Grid item xs={12} alignContent="center">
                                <Button fullWidth color="secondary" onClick={() => handleClickDetails(plan)}>Mais detalhes</Button>
                              </Grid>
                              <Grid item xs={12}>
                                {!plan.esgotado &&
                                  <Button fullWidth color={plan.isRenovacao ? "secondary" : "primary"} variant="contained" size="medium" onClick={() => handleClickBuy(plan.id)}>
                                    {plan.isRenovacao &&
                                      "Renovar"
                                    }
                                    {!plan.isRenovacao &&
                                      "Comprar"
                                    }

                                  </Button>
                                }
                                {plan.esgotado &&
                                  <Button fullWidth className={classes.btnEsgotado} variant="contained" size="medium">Esgotado</Button>
                                }
                              </Grid>
                            </Grid>


                          </CardFooter>
                        </Card>
                      </Grid>
                    </Grow>

                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Content>
        </div>
      </div>
      <ScrollTop >
        <Fab color="primary" size="small" aria-label="Voltar ao topo">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
      <Dialog PaperProps={{ className: classes.overflowCard }} maxWidth="xs" fullWidth onClose={handleCloseModal} aria-describedby="form-dialog-title" open={openModal}>

        <SignInModal />

      </Dialog>
      <DialogPlanDetails buyButton={true} buyClick={(plano: IPlanItem) => handleClickBuy(plano.id)} onClose={handleCloseModalDetails} open={openDetails} plan={planSelected} />
    </>
  )

}


export default withRouter(List);
